import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { HomeState } from '../../../types/states/home.state.type'
import type { AppModel } from '../../../types/models/home.model.type'

const initialState: HomeState = {
  expiredPayments: {
    isFetching: false,
    data: [],
    error: undefined
  }
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // Expired payments
    rxStartFetchingExpiredPayments: (state) => {
      state.expiredPayments.isFetching = true
    },
    rxSuccessFetchingExpiredPayments: (state, { payload }: PayloadAction<AppModel[]>) => {
      state.expiredPayments.isFetching = false
      state.expiredPayments.data = payload
      state.expiredPayments.error = undefined
    },
    rxErrorFetchingExpiredPayments: (state, { payload }: PayloadAction<string>) => {
      state.expiredPayments.isFetching = false
      state.expiredPayments.data = []
      state.expiredPayments.error = payload
    },
  },
})

export const homeSelector = (state: { home: HomeState }) => state.home

export const { rxStartFetchingExpiredPayments, rxSuccessFetchingExpiredPayments, rxErrorFetchingExpiredPayments } = homeSlice.actions
export default homeSlice.reducer