import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { DeviceState } from "../../../types/states/device.state.type";
import type { CatalogDevice, DevicePricing } from "../../../types/models/device.model.type";

const initialState: DeviceState = {
	isFetching: false,
	data: [],
	error: undefined,
	selectedDevice: undefined,
	isSaving: false,
	modalForm: {
		isOpen: false,
		deviceData: undefined,
	},
	priceModalForm: {
		isOpen: false,
		deviceData: undefined,
	},
};

const deviceSlice = createSlice({
	name: "device",
	initialState,
	reducers: {
		rxStartFetching: (state) => {
			state.isFetching = true;
		},
		rxSuccessFetchingData: (state, { payload }: PayloadAction<CatalogDevice[]>) => {
			state.isFetching = false;
			state.data = payload;
			state.error = undefined;
			if (state.selectedDevice) {
				const foundSelected = payload.find((e) => e.id === state.selectedDevice!.id);
				if (!foundSelected) {
					state.selectedDevice = undefined;
				}
			}
		},
		rxErrorFetchingData: (state, { payload }: PayloadAction<string>) => {
			state.isFetching = false;
			state.data = [];
			state.error = payload;
		},

		// Saving data device
		rxStartSavingDevice: (state, { payload }: PayloadAction<boolean | undefined>) => {
			state.isSaving = true;
		},
		rxSuccessSavingDevice: (state, { payload }: PayloadAction<CatalogDevice | undefined>) => {
			if (state.modalForm.deviceData && payload) {
				state.modalForm.deviceData = payload;
				state.selectedDevice = payload;
			}
			state.isSaving = false;
			state.modalForm.isOpen = false;
		},

		// Device selection on table
		rxSetSelectedDevice: (state, { payload }: PayloadAction<CatalogDevice>) => {
			if (state.selectedDevice) {
				state.selectedDevice = state.selectedDevice.id === payload.id ? undefined : payload;
			} else {
				state.selectedDevice = payload;
			}
		},

		rxSetExpandedSelectedDevice: (state, { payload }: PayloadAction<CatalogDevice>) => {
			state.selectedDevice = payload;
		},

		// Device Modal Form
		rxOpenModalForm: (state, { payload }: PayloadAction<CatalogDevice | undefined>) => {
			state.modalForm = {
				isOpen: true,
				deviceData: payload,
			};
		},
		rxCloseModalForm: (state) => {
			state.modalForm = {
				...state.modalForm,
				isOpen: false,
			};
		},

		// Device Price Modal Form
		rxOpenPriceModalForm: (state, { payload }: PayloadAction<{ device: CatalogDevice; price?: DevicePricing | undefined }>) => {
			state.priceModalForm = {
				isOpen: true,
				deviceData: payload.device,
				priceData: payload.price,
			};
		},
		rxClosePriceModalForm: (state) => {
			state.priceModalForm = {
				...state.priceModalForm,
				isOpen: false,
			};
		},
	},
});

export const deviceSelector = (state: { device: DeviceState }) => state.device;

export const {
	rxStartFetching,
	rxSuccessFetchingData,
	rxErrorFetchingData,
	rxSetSelectedDevice,
	rxSetExpandedSelectedDevice,
	rxOpenModalForm,
	rxCloseModalForm,
	rxOpenPriceModalForm,
	rxClosePriceModalForm,
	rxStartSavingDevice,
	rxSuccessSavingDevice,
} = deviceSlice.actions;
export default deviceSlice.reducer;
