import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";

import commonSlice from "../features/common/common.slice";
import authSlice from "../features/auth/auth.slice";
import layoutSlice from "../features/layout/layout.slice";
import deviceSlice from "../features/device/device.slice";
import clientSlice from "../features/client/client.slice";
import loanSlice from "../features/loan/loan.slice";
import loanSummarySlice from "../features/loanSummary/loanSummary.slice";
import deadlineSlice from "../features/deadline/deadline.slice";
import homeSlice from "../features/home/home.slice";
import enrollmentSlice from "../features/enrollment/enrollment.slice";

import { apiService } from "../services/api.service";

const combinedReducer = combineReducers({
	[apiService.reducerPath]: apiService.reducer,
	common: commonSlice,
	auth: authSlice,
	layout: layoutSlice,
	device: deviceSlice,
	client: clientSlice,
	loan: loanSlice,
	loanSummary: loanSummarySlice,
	deadline: deadlineSlice,
	home: homeSlice,
	enrollment: enrollmentSlice,
});

const rootReducer = (state: any, action: AnyAction) => {
	if (action.type === "auth/setCredentials") {
		state = undefined;
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(apiService.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
