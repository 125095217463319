import BusinessLogo from "../../../assets/images/logo.jpg";

const NextGlobalLoader = () => {
  return (
    <div className="layer-global-loader next-global-loader items-center">
      <img alt="Megacell loader" src={BusinessLogo} className="my-auto" />
    </div>
  );
};

export default NextGlobalLoader;
