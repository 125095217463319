import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { UserCredential } from "firebase/auth"

import type { RootState } from '../../store'
import type { AuthState } from '../../../types/states/auth.state.type'

const initialState: AuthState = {
  credentials: null,
  isSignIn: false,
  isSignOut: false,
  errorLogin: undefined
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    rxSetCredentials: (state, { payload }: PayloadAction<UserCredential>) => {
      state.credentials = payload
    },
    rxClearCredentials: (state) => {
      state.credentials = null
    }
  },
})

export const authSelector = (state: { auth: AuthState }) => state.auth
export const selectCurrentUser = (state: RootState) => state.auth.credentials

export const { rxSetCredentials, rxClearCredentials } = authSlice.actions
export default authSlice.reducer