import { useState } from "react";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import screenfull from "screenfull";

import { layoutSelector } from "../../../../app/features/layout/layout.slice";

import RoundedIconButton from "../../../../components/global/RoundedIconButton";
import { UserPill } from "./UserPill";

const DashboardHeader = () => {
  const [isFullScreenActived, setIsFullScreenActived] = useState(false);
  const { pageTitle } = useSelector(layoutSelector);

  const onToggleFullScreen = () => {
    if (screenfull.isFullscreen) {
      screenfull.exit().then(() => {
        setIsFullScreenActived(false);
      });
    } else {
      screenfull.request().then(() => {
        setIsFullScreenActived(true);
      });
    }
  };

  return (
    <div className="w-full h-16 flex items-center px-4">
      <div className="flex items-center h-16 w-full">
        <div className="flex items-center">
          {pageTitle.icon && <i className={`far fa-${pageTitle.icon} mr-2 fa-fw w-8`} />}
          <Typography.Text className="font-bold text-lg w-48 sm:w-80 md:w-96 lg:w-full" ellipsis={{ tooltip: pageTitle.title }}>
            {pageTitle.title}
          </Typography.Text>
        </div>
        <div className="flex items-center ml-auto">
          {screenfull.isEnabled && (
            <RoundedIconButton
              icon={isFullScreenActived ? "compress" : "expand"}
              iconStyle={{ marginTop: "2px" }}
              // tooltip={{
              //   title: isFullScreenActived ? "Salir de pantalla completa" : "Pantalla completa",
              //   placement: "left",
              // }}
              shadow
              onClick={onToggleFullScreen}
              // classes="mr-2"
            />
          )}
          {/* <RoundedIconButton icon="bell" shadow /> */}
          <UserPill />
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
