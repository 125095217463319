import { ConfigProvider, message } from "antd";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import esES from "antd/locale/es_ES";

import { store } from "../../app/store";
import Routing from "../routing";

import "../../assets/styles/main.css";
import "../../assets/icons/fontawesome/css/all.min.css";

dayjs.locale("es-mx");
message.config({ maxCount: 2 });

const App = () => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={esES}>
        <IntlProvider locale="es" defaultLocale="es">
          <BrowserRouter basename="/">
            <Routing />
          </BrowserRouter>
        </IntlProvider>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
