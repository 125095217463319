import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Divider, Tooltip, message } from "antd";

import { onLogout } from "../../../../app/features/auth/auth.thunk";
import { authSelector } from "../../../../app/features/auth/auth.slice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux.hook";

import NextSpinner from "../../../../components/next/NextSpinner";
import { auth } from "../../../../app/services/firebase.service";

export const UserPill = () => {
  const dispatch = useAppDispatch();
  const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false);

  const currentUser = auth.currentUser;

  const { isSignOut } = useAppSelector(authSelector);
  const hideTooltip = () => setVisibleTooltip(false);

  const onLogoutSession = async () => {
    try {
      await dispatch(onLogout());
    } catch (error: any) {
      message.error("Ha ocurrido un error al cerrar la sesión");
    }
  };

  const solutions = [
    // {
    //   name: "Mi cuenta",
    //   description: "Gestiona tu información personal",
    //   href: "##",
    //   icon: "briefcase",
    // },
    {
      name: "Documentación",
      description: "Empiece a gestionar guías de remisión",
      href: "##",
      icon: "book-open-cover",
    },
  ];

  return (
    <Popover>
      {({ open }) => (
        <>
          <Tooltip
            showArrow={false}
            placement="bottomLeft"
            overlayClassName="p-0"
            open={!open && visibleTooltip}
            onOpenChange={(v) => setVisibleTooltip(v)}
            title={
              <div className="font-semibold">
                <div>Cuenta de Megacell</div>
                {/* <div className="text-gray-400 -mb-1">{currentUser?.email}</div> */}
                <div className="text-gray-400">{currentUser?.email}</div>
              </div>
            }
          >
            <Popover.Button as="div" className="ml-4 cursor-pointer" onClick={hideTooltip}>
              <div className="overflow-hidden relative w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
                <svg className="absolute -left-1 w-12 h-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>
                </svg>
              </div>
            </Popover.Button>
          </Tooltip>
          <Popover.Overlay className="z-10 fixed inset-0 bg-black opacity-30" />
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel static className="absolute right-4 z-10 mt-3">
              <div className="overflow-hidden rounded-lg shadow-lg bg-red-100 ring-1 ring-black ring-opacity-5 w-96">
                <div className="relative grid gap-8 bg-white p-7">
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                        <i className={`fad fa-${item.icon} text-gray-600 fa-2x`} />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium text-gray-900">{item.name}</p>
                        <p className="text-sm text-gray-500">{item.description}</p>
                      </div>
                    </a>
                  ))}
                </div>
                <div className="bg-gray-50 p-4">
                  <a href="##" className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                    <span className="flex items-center">
                      <span className="text-sm font-medium text-gray-900">Buzón de Sugerencias</span>
                    </span>
                    <span className="block text-sm text-gray-500 break-all">Envíanos tus comentarios sobre el uso del sistema, esto servirá para brindarle una mejor experiencia de uso.</span>
                  </a>
                </div>
                <Divider className="m-0 bg-gray-100" />
                <div className="bg-gray-50 p-4">
                  <NextSpinner spinning={isSignOut}>
                    <div
                      onClick={onLogoutSession}
                      className="cursor-pointer flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-red-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <span className="flex items-center">
                        <i className="far fa-power-off text-red-600 mr-3" />
                        <span className="text-sm font-medium text-red-600">Cerrar sesión</span>
                      </span>
                    </div>
                  </NextSpinner>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
