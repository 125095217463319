import { Routes, Route, Navigate } from "react-router-dom";

import { AppPaths, AppRoutes } from "../../utils/mapping/appRoutes.mapping";

import {
	ClientsPage,
	DevicesPage,
	HomePage,
	LoansPage,
	LoginPage,
	NotFoundPage,
	SettingsPage,
	DeadlineSettingsPage,
	ReportsPage,
	EnrollmentsPage,
	ManageEnrollmentPage,
	LoanSummaryPage,
} from "../pages";
import { PublicLayout, DashboardLayout } from "../layouts";

const Routing = () => {
	return (
		<Routes>
			<Route path={AppPaths.root}>
				<Route path={AppPaths.empty} element={<DashboardLayout />}>
					<Route path={AppPaths.dashboard} element={<HomePage />} />
					<Route path={AppPaths.loans}>
						<Route index element={<LoansPage />} />
						<Route path=":id" element={<LoanSummaryPage />} />
					</Route>
					<Route path={AppPaths.enrollments}>
						<Route index element={<EnrollmentsPage />} />
						<Route path=":id" element={<ManageEnrollmentPage />} />
					</Route>
					<Route path={AppPaths.devices} element={<DevicesPage />} />
					<Route path={AppPaths.clients} element={<ClientsPage />} />
					<Route path={AppPaths.reports} element={<ReportsPage />} />
					<Route path={AppPaths.settings}>
						<Route index element={<SettingsPage />} />
						<Route path={AppPaths.deadlineSettingsParams} element={<DeadlineSettingsPage />} />
					</Route>
					<Route path={AppPaths.empty} element={<Navigate to={AppRoutes.dashboard} replace />} />
				</Route>
				<Route path={AppPaths.login} element={<PublicLayout />}>
					<Route index element={<LoginPage />} />
				</Route>
				<Route path="*" element={<NotFoundPage />} />
			</Route>
		</Routes>
	);
};

export default Routing;
