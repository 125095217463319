import { signInWithEmailAndPassword } from "firebase/auth"
import { FirebaseError } from "@firebase/util"
import { message } from "antd"
import { AnyAction, Dispatch } from "@reduxjs/toolkit"

import type { LoginRequest } from "../../../types/request/auth.request.type"
import { auth } from "../../services/firebase.service"
import { rxClearCredentials, rxSetCredentials } from "./auth.slice"
import { DictionaryHelper } from "../../../utils/helpers/dictionary.helper"

const onLogin = (options: LoginRequest) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const res = await signInWithEmailAndPassword(auth, options.email, options.password)
    dispatch(rxSetCredentials(res))
  } catch (error: any) {
    if (error instanceof FirebaseError) {
      const errorMessage = DictionaryHelper.firebaseAuth(error.code);
      message.warning(errorMessage)
    }
  }
}

const onLogout = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    await auth.signOut()
    dispatch(rxClearCredentials())
  } catch (error: any) {
    if (error instanceof FirebaseError) {
      message.warning("Error al cerrar sesión")
    }
  }
}

export { onLogin, onLogout }