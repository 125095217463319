import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Tag } from "antd";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { commonSelector, rxCloseHelperStatusModal } from "../../../app/features/common/common.slice";
import { helperStatusType, TCommonHelperStatus } from "../../../utils/mapping/helperStatusType.mapping";

import CloseModalButton from "../../Buttons/CloseModalButton";
import FaIcon from "../../global/FaIcon";

const NextHelperStatus = () => {
  const dispatch = useAppDispatch();
  const { modalStatusHelper } = useAppSelector(commonSelector);

  const { isOpen, type } = modalStatusHelper;

  const onCloseModal = () => dispatch(rxCloseHelperStatusModal());

  let helperStatus: TCommonHelperStatus | null = null;

  if (type) {
    helperStatus = helperStatusType[type];
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 px-6 pt-4 pb-2 text-gray-900 flex items-center">
                  Portal de ayuda para estados
                  <CloseModalButton onClick={onCloseModal} />
                </Dialog.Title>
                <div className="px-6 pb-6 pt-2" style={{ maxHeight: "calc(100vh - 6rem)" }}>
                  {helperStatus && (
                    <>
                      {helperStatus.label}
                      <div className="mt-4 flex grid divide-y divide-gray-50">
                        {helperStatus.items.map(
                          (item, key) =>
                            item.visible && (
                              <div key={key} className="flex items-center h-14 divide-x hover:bg-gray-100 rounded-md p-4">
                                <div className="w-36 mr-3">
                                  <Tag color={item.bgColor} className="w-full text-center">
                                    {item.icon && <FaIcon icon={item.icon} />}
                                    {item.label}
                                  </Tag>
                                </div>
                                <div className="pl-3 max-w-4xl text-justify">{item.description}</div>
                              </div>
                            )
                        )}
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NextHelperStatus;
