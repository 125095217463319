import { Outlet } from "react-router-dom";
import { Scrollbars } from "@rastopyr/react-custom-scrollbar";

import DashboardHeader from "../Header";

const DashboardContent = () => {
  return (
    <div className="h-screen w-full" style={{ maxWidth: `100%`, maxHeight: `calc(100vh + 14rem` }}>
      <DashboardHeader />
      <Scrollbars autoHide>
        <div className="p-4 mb-16">
          <Outlet />
        </div>
      </Scrollbars>
    </div>
  );
};

export default DashboardContent;
