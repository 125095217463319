import { MouseEventHandler } from "react";

type Position = "left" | "right";
type PrefixFaIcon = "far" | "fal" | "fas" | "fad";

type Props = {
  icon: string;
  prefix?: PrefixFaIcon | undefined;
  placement?: Position | "left";
  classes?: string | undefined;
  onClick?: MouseEventHandler<HTMLElement> | undefined;
};

const FaIcon = ({ prefix = "far", placement, icon, classes = "", onClick }: Props) => {
  let margin = "mr-2";
  if (placement === "right") margin = "ml-2";
  return <i className={`${prefix} fa-${icon} ${margin} fa-fw ${classes}`} onClick={onClick} />;
};

export default FaIcon;
