import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "../../../app/services/firebase.service";
import NextHelperStatus from "../../../components/next/NextHelperStatus";

import DashboardContent from "./Content";
import DashboardSidebar from "./Sidebar";

const DashboardLayout = () => {
  const navigate = useNavigate();

  const [validating, setValidating] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("login", { replace: true });
      } else {
        setValidating(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white h-screen w-screen overflow-hidden">
      {validating && <div>Espere...</div>}
      {!validating && (
        <div className="flex">
          <DashboardSidebar />
          <DashboardContent />
        </div>
      )}
      <NextHelperStatus />
    </div>
  );
};

export default DashboardLayout;
