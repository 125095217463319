const envBackenUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:80"
const envFrontendUrl = process.env.REACT_APP_FRONTEND_URL ?? window.location.host
const envCryptoToken = process.env.CRYPTO_TOKEN ?? window.location.host
const envApiKey = process.env.REACT_APP_API_KEY
const envAuthDomain = process.env.REACT_APP_AUTH_DOMAIN
const envProjectId = process.env.REACT_APP_PROJECT_ID
const envStorageBucket = process.env.REACT_APP_STORAGE_BUCKET
const envMessagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID
const envAppId = process.env.REACT_APP_APP_ID
const envMeasurementId = process.env.REACT_APP_MEASUREMENT_ID
const envApiPeruToken = process.env.API_PERU_TOKEN

export module EnvironmentConfig {
  export const backendUrl: string = envBackenUrl
  export const frontendUrl: string = envFrontendUrl
  export const cryptoToken: string = envCryptoToken
  export const apiKey: string = envApiKey!
  export const authDomain: string = envAuthDomain!
  export const projectId: string = envProjectId!
  export const storageBucket: string = envStorageBucket!
  export const messagingSenderId: string = envMessagingSenderId!
  export const appId: string = envAppId!
  export const measurementId: string = envMeasurementId!
  export const apiPeruToken: string = envApiPeruToken!
}