import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { auth } from "../../../app/services/firebase.service";
import { AppRoutes } from "../../../utils/mapping/appRoutes.mapping";

const PublicLayout = () => {
  const navigate = useNavigate();

  const [validating, setValidating] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        navigate(AppRoutes.dashboard, { replace: true });
        setValidating(false);
      } else {
        setValidating(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (validating) {
    return <div>Espere...</div>;
  }

  return (
    <div className="h-screen w-screen">
      <Outlet />
    </div>
  );
};

export default PublicLayout;
