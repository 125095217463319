import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { DeadlineState } from '../../../types/states/deadline.state.type'
import type { Deadline } from '../../../types/models/deadline.type'

const initialState: DeadlineState = {
  isFetching: false,
  data: [],
  error: undefined,
  selectedDeadline: undefined,
  isSaving: false,
  modalForm: {
    isOpen: false,
    deadlineData: undefined
  }
}

const deadlineSlice = createSlice({
  name: 'deadline',
  initialState,
  reducers: {
    rxStartFetching: (state) => {
      state.isFetching = true
    },
    rxSuccessFetchingData: (state, { payload }: PayloadAction<Deadline[]>) => {
      state.isFetching = false
      state.data = payload
      state.error = undefined
      if (state.selectedDeadline) {
        const foundSelected = payload.find(e => e.id === state.selectedDeadline!.id)
        if (!foundSelected) {
          state.selectedDeadline = undefined
        }
      }
    },
    rxErrorFetchingData: (state, { payload }: PayloadAction<string>) => {
      state.isFetching = false
      state.data = []
      state.error = payload
    },

    // Saving data deadline
    rxStartSavingDeadline: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.isSaving = true
    },
    rxSuccessSavingDeadline: (state, { payload }: PayloadAction<Deadline | undefined>) => {
      if (state.modalForm.deadlineData && payload) {
        state.modalForm.deadlineData = payload
        state.selectedDeadline = payload
      }
      state.isSaving = false
      state.modalForm.isOpen = false
    },

    // Deadline selection on table
    rxSetSelectedDeadline: (state, { payload }: PayloadAction<Deadline>) => {
      if (state.selectedDeadline) {
        state.selectedDeadline = state.selectedDeadline.id === payload.id ? undefined : payload
      } else {
        state.selectedDeadline = payload
      }
    },

    // Modal Form
    rxOpenModalForm: (state, { payload }: PayloadAction<Deadline | undefined>) => {
      state.modalForm = {
        isOpen: true,
        deadlineData: payload
      }
    },
    rxCloseModalForm: (state) => {
      state.modalForm = {
        ...state.modalForm,
        isOpen: false,
      }
    },
  },
})

export const deadlineSelector = (state: { deadline: DeadlineState }) => state.deadline

export const { rxStartFetching, rxSuccessFetchingData, rxErrorFetchingData, rxSetSelectedDeadline, rxOpenModalForm, rxCloseModalForm, rxStartSavingDeadline, rxSuccessSavingDeadline } = deadlineSlice.actions
export default deadlineSlice.reducer