import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { ClientState } from '../../../types/states/client.state.type'
import type { Client } from '../../../types/models/client.model.type'

const initialState: ClientState = {
  isFetching: false,
  data: [],
  error: undefined,
  selectedClient: undefined,
  isSaving: false,
  modalForm: {
    isOpen: false,
    clientData: undefined
  }
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    rxStartFetching: (state) => {
      state.isFetching = true
    },
    rxSuccessFetchingData: (state, { payload }: PayloadAction<Client[]>) => {
      state.isFetching = false
      state.data = payload
      state.error = undefined
      if (state.selectedClient) {
        const foundSelected = payload.find(e => e.id === state.selectedClient!.id)
        if (!foundSelected) {
          state.selectedClient = undefined
        }
      }
    },
    rxErrorFetchingData: (state, { payload }: PayloadAction<string>) => {
      state.isFetching = false
      state.data = []
      state.error = payload
    },

    // Saving data client
    rxStartSavingClient: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.isSaving = true
    },
    rxSuccessSavingClient: (state, { payload }: PayloadAction<Client | undefined>) => {
      if (state.modalForm.clientData && payload) {
        state.modalForm.clientData = payload
        if (state.selectedClient) {
          state.selectedClient = payload
        }
      }
      state.isSaving = false
      state.modalForm.isOpen = false
    },

    // Client selection on table
    rxSetSelectedClient: (state, { payload }: PayloadAction<Client>) => {
      if (state.selectedClient) {
        state.selectedClient = state.selectedClient.id === payload.id ? undefined : payload
      } else {
        state.selectedClient = payload
      }
    },

    // Modal Form
    rxOpenModalForm: (state, { payload }: PayloadAction<Client | undefined>) => {
      state.modalForm = {
        isOpen: true,
        clientData: payload
      }
    },
    rxCloseModalForm: (state) => {
      state.modalForm = {
        ...state.modalForm,
        isOpen: false,
      }
    },
  },
})

export const clientSelector = (state: { client: ClientState }) => state.client

export const { rxStartFetching, rxSuccessFetchingData, rxErrorFetchingData, rxSetSelectedClient, rxOpenModalForm, rxCloseModalForm, rxStartSavingClient, rxSuccessSavingClient } = clientSlice.actions
export default clientSlice.reducer