import { TRootStatus } from "../../types/ux/status.ux.type";
import { appStatus, loanStatus, paymentStatus } from "./statusLoan.mapping";

export declare type TCommonHelperStatus = {
  label: string,
  items: TRootStatus[]
}

declare type THelperStatus = {
  [key: string]: TCommonHelperStatus
}

const loanStatusItems = Object.keys(loanStatus).map(key => ({ key, ...loanStatus[key] } as TRootStatus))
const paymentStatusItems = Object.keys(paymentStatus).map(key => ({ key, ...paymentStatus[key] } as TRootStatus))
const appStatusItems = Object.keys(appStatus).map(key => ({ key, ...appStatus[key] } as TRootStatus))

export const helperStatusType: THelperStatus = {
  "LOAN": { label: "Estados del crédito otorgado", items: loanStatusItems } as TCommonHelperStatus,
  "PAYMENT": { label: "Estados del pago de la cuota actual", items: paymentStatusItems } as TCommonHelperStatus,
  "APP": { label: "Estados del aplicativo móvil de bloqueo", items: appStatusItems } as TCommonHelperStatus,
}