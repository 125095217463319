import Loadable from "react-loadable";

import NextGlobalLoader from "../../components/next/NextGlobalLoader";

// Exceptions pages
export const NotFoundPage = Loadable({
	loader: () => import("./Exceptions/NotFoundPage"),
	loading: NextGlobalLoader,
});

// Outer pages
export const LoginPage = Loadable({
	loader: () => import("./Login"),
	loading: NextGlobalLoader,
});

// Dashboard pages
export const HomePage = Loadable({
	loader: () => import("./Home"),
	loading: NextGlobalLoader,
});

export const LoansPage = Loadable({
	loader: () => import("./Loans"),
	loading: NextGlobalLoader,
});

export const LoanSummaryPage = Loadable({
	loader: () => import("./Loans/Summary"),
	loading: NextGlobalLoader,
});

export const EnrollmentsPage = Loadable({
	loader: () => import("./Enrollments"),
	loading: NextGlobalLoader,
});

export const ManageEnrollmentPage = Loadable({
	loader: () => import("./ManageEnrollment"),
	loading: NextGlobalLoader,
});

export const DevicesPage = Loadable({
	loader: () => import("./Devices"),
	loading: NextGlobalLoader,
});

export const ClientsPage = Loadable({
	loader: () => import("./Clients"),
	loading: NextGlobalLoader,
});

export const ReportsPage = Loadable({
	loader: () => import("./Reports"),
	loading: NextGlobalLoader,
});

export const SettingsPage = Loadable({
	loader: () => import("./Settings"),
	loading: NextGlobalLoader,
});

export const DeadlineSettingsPage = Loadable({
	loader: () => import("./Settings/Deadline"),
	loading: NextGlobalLoader,
});
