import { TRootStatus } from "../../types/ux/status.ux.type";

type TStatus = {
  [key: string]: TRootStatus
}

export const loanStatus: TStatus = {
  "A": { label: "Activo", bgColor: "blue", icon: "square-pen", description: "El crédito otorgado se encuentra en proceso.", visible: true } as TRootStatus,
  "C": { label: "Cancelado", bgColor: "red", icon: "xmark-circle", description: "El crédito otorgado ha sido cancelado.", visible: true } as TRootStatus,
  "F": { label: "Finalizado", bgColor: "green", icon: "check-circle", description: "El pago total de la deuda del crédito otrogado ha sido concretada.", visible: true } as TRootStatus,
}

export const paymentStatus: TStatus = {
  "E": { label: "Expirado", bgColor: "red", icon: "triangle-exclamation", description: "El tiempo límite de pago ha expirado.", visible: true } as TRootStatus,
  "R": { label: "Pendiente", bgColor: "orange", icon: "clock", description: "La cuota aún no fue pagado.", visible: true } as TRootStatus,
  "P": { label: "Pagado", bgColor: "green", icon: "check-circle", description: "La cuota ha sido pagado satisfactoriamente.", visible: true } as TRootStatus,
}

export const appStatus: TStatus = {
  "P": { label: "No Vinculado", bgColor: "red", icon: "xmark-circle", visible: true, description: "El aplicativo móvil aún no se ha vinculado con el dispositivo." } as TRootStatus,
  "I": { label: "Instalado", bgColor: "green", icon: "check-circle", visible: true, description: "El aplicativo móvil de bloqueo se encuentra instalado en el dispositivo." } as TRootStatus,
  "D": { label: "Desinstalado", bgColor: "orange", icon: "circle-exclamation", visible: true, description: "El aplicativo móvil de bloqueo fue desinstalado del dispositivo." } as TRootStatus,
}