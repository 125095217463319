export module AppPaths {
  export const root: string = "/"
  export const empty: string = ""
  export const login: string = "login"
  export const dashboard: string = "dashboard"
  export const devices: string = "devices"
  export const loans: string = "loans"
  export const enrollments: string = "enrollments"
  export const clients: string = "clients"
  export const settings: string = "settings"
  export const reports: string = "reports"
  export const deadlineSettingsParams: string = "deadline"
}

export module AppRoutes {
  export const dashboard: string = `/${AppPaths.dashboard}`
  export const loans: string = `/${AppPaths.loans}`
  export const enrollments: string = `/${AppPaths.enrollments}`
  export const clients: string = `/${AppPaths.clients}`
  export const devices: string = `/${AppPaths.devices}`
  export const settings: string = `/${AppPaths.settings}`
  export const reports: string = `/${AppPaths.reports}`
  export const deadlineSettingsParams: string = `/${AppPaths.settings}/${AppPaths.deadlineSettingsParams}`
}