import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

import { useAppSelector } from "../../../../hooks/redux.hook";
import { layoutSelector, onToggleVisibleSidebar } from "../../../../app/features/layout/layout.slice";

import SidebarItem from "./SidebarItem";
import RoundedIconButton from "../../../../components/global/RoundedIconButton";
import BusinessLogo from "../../../../assets/images/logo.jpg";
import { sidebarItems } from "../../../../utils/mapping/sidebarItems.mapping";

const DashboardSidebar = () => {
  const dispatch = useDispatch();

  const { openSidebar: open } = useAppSelector(layoutSelector);

  const onToggleSidebar = () => {
    dispatch(onToggleVisibleSidebar());
  };

  return (
    <motion.div className="w-56 bg-white" animate={{ width: open ? "14rem" : "4.5rem" }} transition={{ duration: 0.5 }}>
      <div className="flex items-center h-16 px-4">
        <motion.div animate={{ width: open ? "100%" : "0" }} transition={{ duration: !open ? 0.5 : 0 }}>
          <img src={BusinessLogo} alt="Logo Empresa" className="h-12 rounded-md" />
        </motion.div>
        <div className={`ml-auto ${open ? "" : "mr-auto"}`}>
          <RoundedIconButton shadow icon={`angles-${open ? "left" : "right"}`} onClick={onToggleSidebar} />
        </div>
      </div>
      <div className="mt-4 px-4">
        {sidebarItems.map((m) => (
          <SidebarItem key={m.id} option={m} />
        ))}
      </div>
    </motion.div>
  );
};

export default DashboardSidebar;
