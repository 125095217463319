import { PropsWithChildren, ReactNode } from "react";
import { Spin } from "antd";

import { CenteredSpinner } from "../../global/Spinner";

declare type Props = PropsWithChildren<{
  spinning: boolean;
  disabled?: boolean | undefined;
  indicator?: ReactNode | undefined;
}>;

const NextSpinner = ({ spinning, disabled = false, indicator, children }: Props) => {
  return (
    <Spin spinning={spinning} indicator={<CenteredSpinner indicator={indicator} />} className={disabled ? "cursor-not-allowed" : ""} style={{ maxHeight: "100%", maxWidth: "100%" }}>
      {children}
    </Spin>
  );
};

export default NextSpinner;
