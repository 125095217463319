import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { CommonState } from '../../../types/states/common.state.type'
import type { TStatusType } from '../../../types/ux/status.ux.type'

const initialState: CommonState = {
  modalStatusHelper: {
    isOpen: false,
    type: undefined
  }
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // Status helper modal
    rxOpenHelperStatusModal: (state, { payload }: PayloadAction<{ type: TStatusType }>) => {
      state.modalStatusHelper = {
        isOpen: true,
        type: payload.type
      }
    },
    rxCloseHelperStatusModal: (state) => {
      state.modalStatusHelper = {
        isOpen: false,
        type: state.modalStatusHelper.type
      }
    },
  },
})

export const commonSelector = (state: { common: CommonState }) => state.common

export const { rxOpenHelperStatusModal, rxCloseHelperStatusModal } = commonSlice.actions
export default commonSlice.reducer