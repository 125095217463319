import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

// import { EnvironmentConfig } from "../../utils/config/environment.config"

// const firebaseConfig = {
//   apiKey: EnvironmentConfig.apiKey,
//   authDomain: EnvironmentConfig.authDomain,
//   projectId: EnvironmentConfig.projectId,
//   storageBucket: EnvironmentConfig.storageBucket,
//   messagingSenderId: EnvironmentConfig.messagingSenderId,
//   appId: EnvironmentConfig.appId,
//   measurementId: EnvironmentConfig.measurementId
// }

const firebaseConfig = {
  apiKey: "AIzaSyD76ruzuOaaSQIHcAvORR_vUkygClbZJSM",
  authDomain: "megacell-due-block.firebaseapp.com",
  projectId: "megacell-due-block",
  storageBucket: "megacell-due-block.appspot.com",
  messagingSenderId: "607273902255",
  appId: "1:607273902255:web:0de1250235f89c29ebb45a",
  measurementId: "G-LJ9DX45ZB9"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
getAnalytics(app)

export { db, auth }