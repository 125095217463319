import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { EnrollmentState } from '../../../types/states/enrollment.state.type'
import type { Enrollment } from '../../../types/models/enrollment.model.type'

const initialState: EnrollmentState = {
  isFetching: false,
  data: [],
  error: undefined,
}

const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    rxStartFetching: (state) => {
      state.isFetching = true
    },
    rxSuccessFetchingData: (state, { payload }: PayloadAction<Enrollment[]>) => {
      state.isFetching = false
      state.data = payload
      state.error = undefined
    },
    rxErrorFetchingData: (state, { payload }: PayloadAction<string>) => {
      state.isFetching = false
      state.data = []
      state.error = payload
    },
  },
})

export const enrollmentSelector = (state: { enrollment: EnrollmentState }) => state.enrollment

export const { rxStartFetching, rxSuccessFetchingData, rxErrorFetchingData } = enrollmentSlice.actions
export default enrollmentSlice.reducer