import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { LoanState } from "../../../types/states/loan.state.type";
import type { Loan } from "../../../types/models/loan.model.type";
import type { Client } from "../../../types/models/client.model.type";
import type { CatalogDevice } from "../../../types/models/device.model.type";
import type { Deadline } from "../../../types/models/deadline.type";
import type { Enrollment } from "../../../types/models/enrollment.model.type";

const initialState: LoanState = {
	isFetching: false,
	data: [],
	error: undefined,
	selectedLoan: undefined,
	isSaving: false,
	modalForm: {
		isOpen: false,
		loanData: undefined,
		enrollment: undefined,
		enrollments: {
			isFetching: false,
			data: [],
		},
		clients: {
			isFetching: false,
			data: [],
		},
		devices: {
			isFetching: false,
			data: undefined,
		},
		deadlines: {
			isFetching: false,
			data: [],
		},
	},
	linkAppModal: {
		isOpen: false,
		loanData: undefined,
	},
};

const loanSlice = createSlice({
	name: "loan",
	initialState,
	reducers: {
		rxStartFetching: (state) => {
			state.isFetching = true;
		},
		rxSuccessFetchingData: (state, { payload }: PayloadAction<Loan[]>) => {
			state.isFetching = false;
			state.data = payload;
			state.error = undefined;
			if (state.selectedLoan) {
				const foundSelected = payload.find((e) => e.id === state.selectedLoan!.id);
				if (!foundSelected) {
					state.selectedLoan = undefined;
				} else {
					state.selectedLoan = foundSelected;
				}
			}
		},
		rxErrorFetchingData: (state, { payload }: PayloadAction<string>) => {
			state.isFetching = false;
			state.data = [];
			state.error = payload;
		},

		// Saving data loan
		rxStartSavingLoan: (state, { payload }: PayloadAction<boolean | undefined>) => {
			state.isSaving = true;
		},
		rxSuccessSavingLoan: (state, { payload }: PayloadAction<Loan | undefined>) => {
			if (state.modalForm.loanData && payload) {
				state.modalForm.loanData = payload;
				state.selectedLoan = payload;
			}
			state.isSaving = false;
			state.modalForm.isOpen = false;
		},

		// Loan selection on table
		rxSetSelectedLoan: (state, { payload }: PayloadAction<Loan>) => {
			if (state.selectedLoan) {
				state.selectedLoan = state.selectedLoan.id === payload.id ? undefined : payload;
			} else {
				state.selectedLoan = payload;
			}
		},

		// Modal Form
		rxOpenModalForm: (state, { payload }: PayloadAction<Loan | undefined>) => {
			state.modalForm = {
				...state.modalForm,
				enrollment: undefined,
				isOpen: true,
				loanData: payload,
			};
		},
		rxCloseModalForm: (state) => {
			state.modalForm = {
				...state.modalForm,
				isOpen: false,
			};
		},

		// Link App Modal
		rxOpenLinkAppModal: (state, { payload }: PayloadAction<Loan | undefined>) => {
			state.linkAppModal = {
				isOpen: true,
				loanData: payload,
			};
		},
		rxCloseLinkAppModal: (state) => {
			state.linkAppModal = {
				...state.linkAppModal,
				isOpen: false,
			};
		},

		// Change selected enrollment
		rxChangeSelectedEnrollment: (state, { payload }: PayloadAction<Enrollment>) => {
			state.modalForm.enrollment = payload;
		},

		// Filtering linked enrollments
		rxStartFilteringLinkedEnrollments: (state) => {
			state.modalForm.enrollments.isFetching = true;
		},
		rxSuccessFilteringLinkedEnrollments: (state, { payload }: PayloadAction<Enrollment[]>) => {
			state.modalForm.enrollments.isFetching = false;
			state.modalForm.enrollments.data = payload;
		},
		rxErrorFilteringLinkedEnrollments: (state) => {
			state.modalForm.enrollments.isFetching = false;
			state.modalForm.enrollments.data = [];
		},
		rxClearFilteredLinkedEnrollments: (state) => {
			state.modalForm.clients.data = [];
		},

		// Filtering clients
		rxStartFilteringClients: (state) => {
			state.modalForm.clients.isFetching = true;
		},
		rxSuccessFilteringClients: (state, { payload }: PayloadAction<Client[]>) => {
			state.modalForm.clients.isFetching = false;
			state.modalForm.clients.data = payload;
		},
		rxErrorFilteringClients: (state) => {
			state.modalForm.clients.isFetching = false;
			state.modalForm.clients.data = [];
		},
		rxClearFilteredClients: (state) => {
			state.modalForm.clients.data = [];
		},

		// Filtering devices
		rxStartFilteringDevices: (state) => {
			state.modalForm.devices.isFetching = true;
		},
		rxSuccessFilteringDevices: (state, { payload }: PayloadAction<CatalogDevice | undefined>) => {
			state.modalForm.devices.isFetching = false;
			state.modalForm.devices.data = payload;
		},
		rxErrorFilteringDevices: (state) => {
			state.modalForm.devices.isFetching = false;
			state.modalForm.devices.data = undefined;
		},

		// Filtering deadlines
		rxStartFilteringDeadlines: (state) => {
			state.modalForm.deadlines.isFetching = true;
		},
		rxSuccessFilteringDeadlines: (state, { payload }: PayloadAction<Deadline[]>) => {
			state.modalForm.deadlines.isFetching = false;
			state.modalForm.deadlines.data = payload;
		},
		rxErrorFilteringDeadlines: (state) => {
			state.modalForm.deadlines.isFetching = false;
			state.modalForm.deadlines.data = [];
		},
	},
});

export const loanSelector = (state: { loan: LoanState }) => state.loan;

export const {
	rxStartFetching,
	rxSuccessFetchingData,
	rxErrorFetchingData,
	rxSetSelectedLoan,
	rxOpenModalForm,
	rxCloseModalForm,
	rxStartSavingLoan,
	rxSuccessSavingLoan,
	rxStartFilteringClients,
	rxSuccessFilteringClients,
	rxErrorFilteringClients,
	rxClearFilteredClients,
	rxStartFilteringDevices,
	rxSuccessFilteringDevices,
	rxErrorFilteringDevices,
	rxStartFilteringDeadlines,
	rxSuccessFilteringDeadlines,
	rxErrorFilteringDeadlines,
	rxOpenLinkAppModal,
	rxCloseLinkAppModal,
	rxChangeSelectedEnrollment,
	rxStartFilteringLinkedEnrollments,
	rxSuccessFilteringLinkedEnrollments,
	rxErrorFilteringLinkedEnrollments,
	rxClearFilteredLinkedEnrollments,
} = loanSlice.actions;
export default loanSlice.reducer;
