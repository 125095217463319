import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// import type { RootState } from '../store'
import { EnvironmentConfig } from '../../utils/config/environment.config'

export const apiService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${EnvironmentConfig.backendUrl}/api`,
    prepareHeaders: (headers, { getState }) => {
      // const token = (getState() as RootState).auth.token
      // if (token) {
      //   headers.set('Authorization', `Bearer ${token}`)
      // }
      headers.set("Access-Control-Allow-Origin", EnvironmentConfig.frontendUrl)
      headers.set("Access-Control-Allow-Header", 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method');
      return headers
    },
  }),
  endpoints: () => ({}),
})