import { useEffect, useState } from "react";
import { Badge, Divider, Tooltip, Typography } from "antd";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";

import type { PageTitle } from "../../../../types/ux/pageTitle.ux.type";
import type { TSidebarItem } from "../../../../types/ux/sidebarItem.ux.type";
import { layoutSelector, onChangePageTitle } from "../../../../app/features/layout/layout.slice";
import FaIcon from "../../../../components/global/FaIcon";

type Props = {
  option: TSidebarItem;
};

const SidebarItem = ({ option }: Props) => {
  const dispatch = useDispatch();

  const { openSidebar: open } = useSelector(layoutSelector);
  const [badgeCounter, setBadgeCounter] = useState<number | undefined>();

  const onChangeOption = () => {
    const pageTitle: PageTitle = { title: option.label, icon: option.icon };
    dispatch(onChangePageTitle(pageTitle));
  };

  useEffect(() => {
    if (option && option.badgeCode) {
      setBadgeCounter(5);
    }
  }, [option]);

  return (
    <Tooltip title={open ? "" : option.label} placement="right">
      <motion.div className="w-48" animate={{ width: open ? "12rem" : "auto" }} transition={{ duration: !open ? 0 : 0.5 }} onClick={onChangeOption}>
        {option.link && (
          <NavLink to={option.link} className={({ isActive }) => `sidebar-item ${isActive ? "active-nav" : "default-nav"} ${open && isActive ? "border-r-4" : "border-r-0"}`}>
            <div className="mr-2">
              <i className={`far fa-${option.icon} fa-fw`} />
            </div>
            <AnimatePresence>
              {open && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="flex items-center w-full">
                  <Typography.Text ellipsis>{option.label}</Typography.Text>
                  {badgeCounter && <Badge count={badgeCounter} className="ml-auto mb-0" overflowCount={10} />}
                </motion.div>
              )}
            </AnimatePresence>
          </NavLink>
        )}
        {option.children && (
          <div className="sidebar-item default-nav">
            <div className="mr-2">
              <i className={`far fa-${option.icon} fa-fw`} />
            </div>
            <AnimatePresence>
              {open && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="flex items-center w-full">
                  <Typography.Text ellipsis>{option.label}</Typography.Text>
                  <FaIcon classes="ml-auto" icon="chevron-down text-sm" />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
        {option.hasDivider && <Divider className="my-3" />}
      </motion.div>
    </Tooltip>
  );
};

export default SidebarItem;
