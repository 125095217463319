import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { LayoutState } from "../../../types/states/layout.state.type";
import type { PageTitle } from "../../../types/ux/pageTitle.ux.type";

const initialState: LayoutState = {
  openSidebar: true,
  pageTitle: {
    title: "Dashboard",
    icon: "home",
  }
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    onToggleVisibleSidebar: (state) => {
      state.openSidebar = !state.openSidebar;
    },
    onChangePageTitle: (state, { payload }: PayloadAction<PageTitle>) => {
      localStorage.setItem('last-menu', JSON.stringify(payload))
      state.pageTitle = payload
    }
  },
});

export const layoutSelector = (state: { layout: LayoutState }) => state.layout;
export const { onToggleVisibleSidebar, onChangePageTitle } = layoutSlice.actions;

export default layoutSlice.reducer;
