import type { TSidebarItem } from "../../types/ux/sidebarItem.ux.type";
import { AppRoutes } from "./appRoutes.mapping";

export const sidebarItems: TSidebarItem[] = [
  { id: 1, label: "Dashboard", icon: "home", link: AppRoutes.dashboard },
  { id: 2, label: "Financiamientos", icon: "file-invoice", link: AppRoutes.loans },
  // { id: 3, label: "Equipo Inscrito", icon: "mobile-signal-out", link: AppRoutes.enrollments, hasDivider: true, badgeCode: "ENROLLMENT" },
  { id: 3, label: "Equipo Inscrito", icon: "mobile-signal-out", link: AppRoutes.enrollments, hasDivider: true },
  { id: 4, label: "Clientes", icon: "user-group", link: AppRoutes.clients },
  { id: 5, label: "Catálogo Equipos", icon: "mobile", link: AppRoutes.devices, hasDivider: true },
  { id: 6, label: "Reportes", icon: "files", link: AppRoutes.reports },
  { id: 7, label: "Configuración", icon: "gear", link: AppRoutes.settings },
];