import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { LoanSummaryState } from "../../../types/states/loan.state.type";
import type { Loan } from "../../../types/models/loan.model.type";

const initialState: LoanSummaryState = {
	isFetching: false,
	isSavingPayment: false,
	data: undefined,
	error: undefined,
};

const loanSummarySlice = createSlice({
	name: "loan-summary",
	initialState,
	reducers: {
		rxStartFetching: (state) => {
			state.isFetching = true;
		},
		rxSuccessFetchingData: (state, { payload }: PayloadAction<Loan>) => {
			state.isFetching = false;
			state.data = payload;
			state.error = undefined;
		},
		rxErrorFetchingData: (state, { payload }: PayloadAction<string>) => {
			state.isFetching = false;
			state.data = undefined;
			state.error = payload;
		},

		// Saving payment
		rxStartSavingPayment: (state) => {
			state.isSavingPayment = true;
		},
		rxSuccessSavingPayment: (state) => {
			state.isSavingPayment = false;
		},
		rxErrorSavingPayment: (state) => {
			state.isSavingPayment = false;
		},
	},
});

export const loanSummarySelector = (state: { loanSummary: LoanSummaryState }) => state.loanSummary;
export const { rxStartFetching, rxSuccessFetchingData, rxErrorFetchingData, rxStartSavingPayment, rxSuccessSavingPayment, rxErrorSavingPayment } = loanSummarySlice.actions;
export default loanSummarySlice.reducer;
