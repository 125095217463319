import { Tooltip } from "antd";

import { TooltipOptions } from "../../../types/ux/tooltip.ux.type";

type Props = {
	icon: string;
	iconClass?: string;
	classes?: string;
	onlyIcon?: boolean;
	iconStyle?: React.CSSProperties;
	tooltip?: TooltipOptions | null;
	shadow?: boolean | undefined;
	onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
};

const RoundedIconButton = ({ icon, iconClass, classes, onlyIcon = false, iconStyle, tooltip, onClick, shadow = true }: Props) => {
	return (
		<Tooltip title={tooltip?.title} placement={tooltip?.placement}>
			<div
				className={`cursor-pointer w-10 h-10 flex items-center justify-center rounded-lg ${!onlyIcon ? "bg-gray-100 hover:bg-gray-200" : ""} ${shadow ? "shadow" : ""} ${classes}`}
				onClick={onClick}
			>
				<i className={`far fa-${icon} ${iconClass}`} style={iconStyle} />
			</div>
		</Tooltip>
	);
};

export default RoundedIconButton;
